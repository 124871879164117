<template>
	<div class="menu">
		<el-container>
			<el-header>
				<top-nav />
			</el-header>
			<el-main>
				<router-view />
			</el-main>
			<div>
				<bottom-nav />
			</div>
		</el-container>
		<back-top v-if="!isMobileEnd" />
	</div>
</template>

<script>
import TopNav from './childCpn/TopNav'
import BottomNav from './childCpn/BottomNav.vue'
import BackTop from './childCpn/BackTop'
export default {
	name: 'Menu',
	data() {
		return {}
	},
	created() {},
	mounted() {},
	methods: {},
	components: {
		TopNav,
		BottomNav,
		BackTop,
	},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},
}
</script>

<style lang="scss" scoped>
.menu {
	margin: 0 auto;
	.el-container {
		width: 100%;
		margin: 0 auto;
		position: relative;
		.el-header,
		.el-main,
		.el-footer {
			padding: 0;
		}
		.el-main {
			// overflow-x: hidden;
			overflow: hidden;
		}
	}
}
.el-header {
	width: 100%;
	height: 0.6rem !important;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	background: rgba($color: #000, $alpha: 0.2);
	z-index: 999;
}
@media screen and (max-width: 768px) {
	.el-header {
		height: 44px !important;
	}
}
</style>
