<!-- 返回顶部 -->
<template>
	<el-backtop :visibility-height="400" :right="30">
		<div class="backTop">
			<el-tooltip class="itemEwm" effect="light" placement="left">
				<div slot="content">
					<div class="backTop-div-content">
						<div class="backTop-div-img">
							<img :src="footerXcx" alt="小程序" />
						</div>
						<div class="backTop-div-text">
							<p>微信扫码体验</p>
							<p>产品性能</p>
						</div>
					</div>
				</div>
				<div class="backTop-ewm" @click="backTopEwm">
					<div class="ewm-icon">
						<i class="iconfont icon-xiaochengxu2"></i>
					</div>

					<span class="ewm-span">扫码体验</span>
				</div>
			</el-tooltip>

			<p class="line"></p>
			<div
				class="backTop-link"
				@click="
					() => {
						this.$router.push('/formSub')
					}
				"
			>
				<i class="iconfont icon-zixun"></i>
				<span>产品咨询</span>
			</div>
			<p class="line"></p>
			<div class="backTop-icon">
				<i class="iconfont icon-zhiding"></i>
				<span>返回顶部</span>
			</div>
		</div>
	</el-backtop>
</template>

<script>
export default {
	name: 'NewsInfo',
	data() {
		return {
			footerXcx: require('@/assets/img/info/xcx.png'),
		}
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		backTopEwm(e) {
			window.event
				? (window.event.cancelBubble = true)
				: e.stopPropagation()
		},
	},
	components: {},
	computed: {},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.el-backtop {
	width: 64px;
	height: 208px;
	bottom: calc(50% - 104px) !important;
	background-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}
.backTop {
	width: 64px;
	height: 210px;
	padding: 10px 0;
	background: #ffffff;
	box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.1);
	border-radius: 10px 10px 10px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	.backTop-ewm {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		.ewm-icon {
			width: 36px;
			height: 36px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			cursor: pointer;
			i {
				line-height: 24px;
				font-size: 24px;
				color: #1d67e8;
			}
		}
		.ewm-icon::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 50%;
			background: rgba(29, 103, 232, 1);
			opacity: 0.16;
			animation: myripple 1s ease-in-out infinite alternate;
		}
		.ewm-span {
			font-weight: 400;
			font-size: 12px;
			color: #1d67e8;
			line-height: 22px;
			font-family: Source Han Sans, Source Han Sans;
		}
	}
	.backTop-link,
	.backTop-icon {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		i {
			display: inline-block;
			font-size: 24px;
			color: #1d67e8;
		}
		span {
			font-weight: 400;
			font-size: 12px;
			color: #1d67e8;
			line-height: 22px;
			font-family: Source Han Sans, Source Han Sans;
		}
	}
	.backTop-icon {
		span {
			line-height: 16px;
		}
	}
	.line {
		width: 44px;
		height: 1px;
		background-color: rgba(204, 204, 204, 0.2);
		margin: 10px 0 9px;
	}
}
// .el-tooltip__popper.is-light
::v-deep .el-tooltip__popper {
	border-color: #ffffff;
	box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.1);
}
::v-deep .el-tooltip__popper .is-light {
	border: #ffffff !important;
	box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.1);
}

.backTop-div-content {
	width: 146px;
	height: 182px;
	border-radius: 10px 10px 10px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.backTop-div-img {
		width: 126px;
		height: 126px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.backTop-div-text {
		margin-top: 10px;
		p {
			font-weight: 400;
			font-size: 12px;
			color: #333333;
			line-height: 18px;
			font-style: normal;
			text-align: center;
		}
	}
}
@keyframes myripple {
	0% {
		transform: scale(0.8);
	}
	to {
		transform: scale(1.08);
	}
}
@media screen and (max-width: 1080px) {
}
@media screen and (max-width: 768px) {
	.el-backtop {
		right: 20px !important;
	}
	.backTop {
		height: 30px;
		.backTop-ewm {
			display: none;
		}
	}
}
</style>
