<!-- 底部导航 -->
<template>
	<div class="footer-warp">
		<div class="footer" v-if="!isMobileEnd">
			<div class="content">
				<div class="inner">
					<div class="footer-content">
						<div class="footer-content-item">
							<h5>关于我们</h5>
							<el-row
								v-for="(i, index) in AboutUsList"
								:key="index"
								><router-link :to="i.keyPath" tag="button">{{
									i.keyName
								}}</router-link></el-row
							>
						</div>
						<div class="footer-content-item">
							<h5>联系我们</h5>
							<div class="companyInfo">
								<p class="companyInfo-phone">
									电话：0571-86929509
								</p>
								<p class="companyInfo-mail">
									邮箱：sales@jet-voice.com
								</p>
								<p class="companyInfo-address">
									公司地址：杭州市余杭区利尔达物联网科技园1幢1102室
								</p>
							</div>
						</div>
						<div class="footer-content-item">
							<h5>关注我们</h5>
							<el-row type="flex">
								<el-col class="footer-imgInfo" :span="12"
									><p class="footer-imgInfo-gzh">
										微信公众号
									</p>
									<div class="footer-gzh" v-viewer>
										<img
											v-lazy="footerGzh"
											:key="footerGzh"
											alt="微信公众号"
										/></div
								></el-col>
								<el-col class="footer-imgInfo" :span="12">
									<p>体验产品性能</p>
									<div class="footer-gzh" v-viewer>
										<img
											v-lazy="footerXcx"
											:key="footerXcx"
											alt="小程序"
										/>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
					<div class="footer-bottom">
						Copyright © 2024 杭州捷途慧声科技有限公司 All rights
						reserved
						<a href="https://beian.miit.gov.cn/" target="_blank"
							>浙ICP备17040694号</a
						>
					</div>
				</div>
			</div>
			<div class="left"></div>
			<div class="right"></div>
		</div>
		<div class="footer-768" v-else>
			<div class="footer-content">
				<div class="footer-img">
					<div class="footer-img-item">
						<div class="footer-gzh">
							<img
								v-lazy="footerGzh"
								:key="footerGzh"
								alt="微信公众号"
							/>
						</div>
						<p>关注微信公众号</p>
					</div>
					<div class="footer-img-item">
						<div class="footer-gzh">
							<img
								v-lazy="footerXcx"
								:key="footerXcx"
								alt="小程序"
							/>
						</div>
						<p>体验产品性能</p>
					</div>
				</div>
				<div class="footer-info">
					<p><i class="iconfont icon-dianhua"></i>0571-86929509</p>
					<p>
						<i class="iconfont icon-youxiang"></i
						>sales@jet-voice.com
					</p>
					<p>
						<i class="iconfont icon-ditu"></i
						>杭州市余杭区利尔达物联网科技园1幢1102室
					</p>
				</div>
			</div>
			<div class="footer-bottom">
				<p>Copyright © 2024 杭州捷途慧声科技有限公司</p>
				<p>
					All rights reserved
					<a href="https://beian.miit.gov.cn/" target="_blank"
						>浙ICP备17040694号</a
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { menuAboutUs } from '@/utils/mock.js'
export default {
	name: 'BottomNav',
	data() {
		return {
			footerGzh: require('@/assets/img/info/gzh.png'),
			footerXcx: require('@/assets/img/info/xcx.png'),
			AboutUsList: menuAboutUs,
			mobileTerminal: false,
		}
	},
	props: {},
	methods: {
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
		activeIndex: {
			get() {
				return this.$store.state.activeIndex
			},
			set(val) {
				this.$store.state.activeIndex = val
			},
		},
	},
	created() {},
	mounted() {
		if ((this, this._isMobile())) {
			this.mobileTerminal = true
		} else {
			this.mobileTerminal = false
		}
	},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.footer-warp {
	background-color: #ffffff;
	padding-bottom: 0.22rem;
	.footer {
		// 三栏都需要浮动
		.center,
		.left,
		.right {
			float: left;
		}
		// 左右两栏固定宽度
		.left,
		.right {
			width: 16.9271vw; // width: 325px
			background-color: #fff;
		}
		// 中间栏  因为需要自适应所以设置宽度100%
		.center {
			width: 100%;
		}
		.left {
			margin-left: -100%;
		}
		.right {
			margin-left: -16.9271vw;
		}
		.inner {
			margin: 0 16.9271vw;
		}

		.content {
			width: 100%;
			.footer-content {
				padding: 36px 0 40px 0;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				border-bottom: 1px solid rgba(204, 204, 204, 0.7);
				&-item {
					width: 25%;
					position: relative;
					h5 {
						font-size: 18px;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 400;
						color: #333333;
						line-height: 1;
						margin: 0 0 20px 0;
					}
					button {
						padding: 0;
						border-width: 0px;
						font-size: 14px;
						color: #666666;
						background-color: transparent;
						border-color: transparent;
						margin-bottom: 16px;
						cursor: pointer;
						&:hover {
							color: #1d67e8;
						}
					}
					&:nth-child(2) {
						width: 50%;
					}
					&:nth-child(3) {
						margin: 0 0 20px 5px;
					}
				}
				.companyInfo {
					color: #666666;
					p {
						font-size: 14px;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 400;
						line-height: 22px;
					}
					&-mail {
						margin: 12px 0;
					}
				}
				.footer-imgInfo {
					p {
						color: #666666;
						font-size: 14px;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 400;
						line-height: 22px;
					}
					.footer-imgInfo-gzh {
						margin-left: 5px;
					}
					.footer-gzh {
						width: 86px;
						height: 86px;
						// border-radius: 0;
						overflow: hidden;
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
			.footer-bottom {
				margin: 24px 0 0 37px;
				font-size: 14px;
				text-align: center;
				font-weight: 400;
				color: #666666;
				font-family: Source Han Sans, Source Han Sans CN;
				a {
					color: #127bf2;
					margin-left: 8px;
					&:hover {
						color: #ff641b;
					}
				}
			}
		}
	}
	.footer-768 {
		padding: 0 15px;
		background-color: #f6f9fb;
		.footer-content {
			padding: 32px 0 16px 0;
			border-bottom: 1px solid rgba(204, 204, 204, 0.7);
			.footer-img {
				display: flex;
				justify-content: center;
				margin-bottom: 16px;
				&-item {
					width: 90px;
					.footer-gzh {
						width: 87px;
						height: 87px;
					}
					p {
						font-size: 12px;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 400;
						color: #333333;
						line-height: 16px;
						text-align: center;
						margin-top: 8px;
					}
				}
				&-item:nth-child(1) {
					margin-right: 40px;
				}
			}
			.footer-info {
				p {
					font-size: 12px;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					line-height: 22px;
					i {
						display: inline-block;
						margin-right: 3px;
					}
				}
			}
		}
		.footer-bottom {
			margin: 14px 0;
			font-size: 10px;
			text-align: center;
			font-weight: 400;
			color: #999999;
			font-family: Source Han Sans, Source Han Sans CN;
			p {
				margin: 5px 0;
			}
			a {
				color: #999999;
				&:hover {
					color: #ff641b;
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
	.footer-warp {
		background-color: #f6f9fb;
	}
}
@media screen and (max-width: 540px) {
}
</style>
